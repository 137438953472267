import { useRouteLoaderData } from "@remix-run/react";

import { loader as rootLoader } from "~/root";

export function useOptionalUser() {
  const data = useRouteLoaderData<typeof rootLoader>("root");
  if (!data?.user) {
    return undefined;
  }
  return data.user;
}
